const cookies = {
  get(key) {
    if (!key) {
      return null;
    }
    const cookies = document.cookie.split("; ").reduce((acc, item) => {
      const parts = item.split("=");
      acc.set(parts[0], parts[1]);
      return acc;
    }, new Map());
    return cookies.get(key);
  },
  csrf() {
    return cookies.get("csrftoken");
  },
};

export default cookies;
