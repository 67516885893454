import {
  IsEmail,
  MaxLength,
  MaxNumber,
  MinLength,
  MinNumber,
  Pattern,
  Required,
  Validator,
} from "@lion/form-core";
import { html } from "lit";
import { isCreditCardNumer } from "../../../legl-ui/utils/functions";

export class LeglRequired extends Required {
  static getMessage(data) {
    const label = data.params?.fieldName ?? data.fieldName;
    return `${label} is required`;
  }
}

export class LeglIsEmail extends IsEmail {
  static getMessage(data) {
    return `${data.fieldName} must be a valid email address`;
  }
}

export class LeglPattern extends Pattern {
  static getMessage(data) {
    return `${data.fieldName} is invalid`;
  }

  execute(value, pattern = this.param) {
    let updatedPattern = pattern;
    // If regex is string force full match, otherwise use standard explicit regexp matching
    if (typeof updatedPattern === "string") {
      updatedPattern = RegExp(`^${pattern}$`);
    }
    return super.execute(value, updatedPattern);
  }
}

export class LeglMinAmount extends MinNumber {
  static getMessage(data) {
    return html`<span
            >The amount entered must be at least
            <legl-currency
                currency="${data.config.currency}"
                locale="${data.config.locale}"
                >${data.params}</legl-currency
            ></span
        >`;
  }
}

export class LeglMaxAmount extends MaxNumber {
  static getMessage(data) {
    return html`<span
            >${data.fieldName} must not be greater than
            <legl-currency
                currency="${data.config.currency}"
                locale="${data.config.locale}"
                >${data.params}</legl-currency
            ></span
        >`;
  }
}

export class LeglMaxLength extends MaxLength {
  static getMessage(data) {
    return `${data.fieldName} must not exceed ${data.params} characters`;
  }
}

export class LeglMinLength extends MinLength {
  static getMessage(data) {
    return `${data.fieldName} must be at least ${data.params} characters long`;
  }
}

export class LeglIsNotCardNumber extends Validator {
  execute(value) {
    const cardNumbers = [
      ...String(value).replaceAll(" ", "").matchAll(/\d+/gm),
    ].filter((match) => isCreditCardNumer(match[0]));
    return cardNumbers.length > 0;
  }

  static getMessage(data) {
    return `${data.fieldName} should not be a credit card number`;
  }
}

export class LeglServerMessagesEmptyValue extends Required {
  static getMessage({ params: message }) {
    return `${message}`;
  }
}

export class LeglNoWhitespace extends Validator {
  static getMessage(data) {
    return `${data.fieldName} cannot be empty space`;
  }

  execute(value) {
    return Boolean(value.trim()) === false;
  }
}
